<template>
  <tasks-list route="/user-tasks" />
</template>

<script>
import TasksList from '@/components/TasksList'

export default {
  components: { TasksList },
}
</script>
