<template>
  <v-card @click="$router.push({name: 'task.execute', params: {id: task.id} })">
    <v-card-title>
      {{ task.type }}
    </v-card-title>

    <v-card-text>
      <div>
        تاريخ انطلاق المهمة:
        {{ task.added_at }}
      </div>
      <div>
        عدد الإعجابات المطلوب: {{ task.required_likes_count }}
      </div>
      <div>
        قيمة المكافئة لكل ألف دعوة: {{ task.bounty }}
      </div>
      <div class="my-4">
        نسبة إنجاز المهمة
        <v-progress-linear
          :value="task.progress"
          height="25"
          striped
          color="light-green"
          class="text--white"
        >
          <template #default="{ value }">
            <strong class="primary--text text--lighten-3">{{ value }}%</strong>
          </template>
        </v-progress-linear>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TaskCard',

  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },

}
</script>
