<template>
  <v-container>
    <h1 class="text-center ma-3">
      ابدأ الآن
    </h1>
    <v-tabs
      :value="selectedTab === 'available-tasks' ? 1:0"
      centered
    >
      <v-tab @click.prevent="selectedTab = 'my-tasks'">
        مهامي
      </v-tab>
      <v-tab @click.prevent="selectedTab = 'available-tasks'">
        المهام المعروضة للنفيذ
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item value="my-tasks">
        <my-tasks />
      </v-tab-item>
      <v-tab-item value="available-tasks">
        <available-tasks />
      </v-tab-item>
    </v-tabs-items>
    <v-btn
      color="primary"
      elevation="2"
      :to="{ name: 'tasks.create' }"
      fixed
      bottom
      left
      fab
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import AvailableTasks from '@/components/AvailableTasks'
import MyTasks from '@/components/MyTasks'

export default {
  components: {
    MyTasks,
    AvailableTasks,
  },

  computed: {
    selectedTab: {
      get () {
        return this.$route.query.tab || 'available-tasks'
      },
      set (value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            tab: value,
          },
        })
      },

    },
  },
}
</script>
