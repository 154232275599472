<template>
  <v-row>
    <template v-if="loading">
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="article, progress-bar"
          :loading="loading"
          width="100%"
        />
      </v-col>
    </template>
    <template v-if="!loading">
      <template v-if="tasks.length">
        <v-col
          v-for="task in tasks"
          :key="task.id"
          cols="12"
          sm="6"
          md="4"
        >
          <v-slide-y-transition>
            <task-card :task="task" />
          </v-slide-y-transition>
        </v-col>
      </template>
      <v-slide-y-transition>
        <v-col v-if="!tasks.length">
          <v-card>
            <v-card-text class="text-center">
              لا يوجد أي مهمات. اضغط على إشارة الزائد في الأسفل لإضافة مهمة أو على تبويبة منفذ الخدمة
              لتنفيذ المهمات المتوفرة
            </v-card-text>
          </v-card>
        </v-col>
      </v-slide-y-transition>
    </template>
  </v-row>
</template>

<script>
import TaskCard from '@/components/TaskCard'

export default {
  components: { TaskCard },

  props: {
    route: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      tasks: [],
      loading: true,
    }
  },

  async created () {
    this.tasks = (await this.axios.get(this.route)).data.data
    this.loading = false
  },
}
</script>
